const ActionPaths = Object.freeze({
  CREATE: 'create',
  DELETE: 'delete',
  EDIT: 'edit',
  LIST: 'list',
  MANAGE: 'manage',
  PUBLISH: 'publish',
  UPDATE: 'update',
});

export const ProductPaths = Object.freeze({
  CRYPTO: 'crypto',
  NAVIGATOR: 'navigator',
  XBTECH: 'xbtech',
});

export const Paths = Object.freeze({
  ...ActionPaths,
  ...ProductPaths,
  ROOT: '/',
  PAGE_NOT_FOUND: ':pathMatch(.*)*',
  ERROR: 'error',
  AUDIT_HISTORY: 'audit-history',
  AUTHENTICATION: 'auth',
  CONTACTS: 'contacts',
  DASHBOARD: 'dashboard',
  DOCUMENT: 'document',
  EEA_DEFINITIONS: 'eea-definitions',
  EMAIL_VERIFIED: 'email-verified',
  GLOBAL_DEFINITIONS: 'global-definitions',
  JURISDICTION_MANAGEMENT: 'jurisdiction-management',
  LEGENDS: 'legends',
  LOCAL_DEFINITIONS: 'local-definitions',
  STATUS_TABLE: 'status-table',
  SUPPORTING_DOCS: 'supporting-docs',
  UNAUTHORISED: 'unauthorised',
  UPDATE_CYCLE: 'update-cycle',
  VERSION: 'version',
});

const ErrorViews = Object.freeze({
  UNAUTHORISED: 'UnauthorisedPage',
  UNHANDLED: 'UnhandledServerErrorPage',
  PAGE_NOT_FOUND: 'PageNotFound',
  EMAIL_VERIFIED: 'AuthVerifyEmailCallback',
});

const ProductViews = Object.freeze({
  CRYPTO: 'Crypto',
  NAVIGATOR: 'Navigator',
  XBTECH: 'XBTech',
});

export const Views = Object.freeze({
  ...ProductViews,
  ...ErrorViews,
  AUTHENTICATION: 'AuthenticationPage',
  AUDIT_HISTORY: 'AuditHistory',
  CONTACTS: 'Contacts',
  DASHBOARD: 'Dashboard',
  DOCUMENT_VERSION: 'DocumentVersion',
  DOCUMENT: 'Document',
  EEA_DEFINITIONS: 'eea-definitions',
  EEA_DEFINITIONS_LIST: 'list',
  EEA_DEFINITIONS_CREATE: 'create',
  EEA_DEFINITIONS_EDIT: 'edit',
  GLOBAL_DEFINITIONS_CREATE: 'GlobalDefinitionsCreate',
  GLOBAL_DEFINITIONS_EDIT: 'GlobalDefinitionsEdit',
  GLOBAL_DEFINITIONS_LIST: 'GlobalDefinitionsList',
  GLOBAL_DEFINITIONS: 'GlobalDefinitions',
  JURISDICTION_MANAGEMENT: 'JurisdictionManagement',
  LEGENDS_CREATE: 'LegendsCreate',
  LEGENDS_LIST: 'LegendsList',
  LEGENDS: 'Legends',
  LOCAL_DEFINITIONS_CREATE: 'LocalDefinitionsCreate',
  LOCAL_DEFINITIONS_LIST: 'LocalDefinitionsList',
  LOCAL_DEFINITIONS: 'LocalDefinitions',
  STATUS_TABLE_CREATE: 'CreateStatusEntry',
  STATUS_TABLE_EDIT: 'EditStatusEntry',
  STATUS_TABLE_MANAGE: 'ManageStatusTable',
  STATUS_TABLE: 'StatusTable',
  SUPPORTING_DOCS_CREATE: 'SupportingDocsCreate',
  SUPPORTING_DOCS_EDIT: 'SupportingDocsEdit',
  SUPPORTING_DOCS_LIST: 'SupportingDocsList',
  SUPPORTING_DOCS: 'SupportingDocs',
  UPDATE_CYCLE_CREATE: 'CreateUpdateCycle',
  UPDATE_CYCLE_EDIT: 'EditUpdateCycle',
  UPDATE_CYCLE_MANAGE: 'ManageUpdateCycle',
  UPDATE_CYCLE_PUBLISH: 'UpdateCyclePublish',
  UPDATE_CYCLE: 'UpdateCycle',
});
