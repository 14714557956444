import cryptoRoutes from '@/products/crypto/routes';
import navigatorRoutes from '@/products/navigator/routes';
import xbtechRoutes from '@/products/xbtech/routes';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import { removePortalSuffix } from '@/utils';
import { PortalProduct } from '../config';
import updateTabTitle from '../utils/updateTabTitle';
import errorRoutes from './errorRoutes';
import { Paths, Views } from './router.config';

const authViewComponent = () => import(
  /* webpackChunkName: "authentication-page" */ '@/views/authentication/AuthenticationView.vue'
);

export const routes: Array<RouteRecordRaw> = [
  ...cryptoRoutes,
  ...navigatorRoutes,
  ...xbtechRoutes,
  {
    path: `/${Paths.AUTHENTICATION}`,
    name: Views.AUTHENTICATION,
    component: authViewComponent,
    meta: {
      title: 'Authentication',
    },
  },
  {
    path: `/${Paths.EMAIL_VERIFIED}`,
    name: Views.EMAIL_VERIFIED,
    component: authViewComponent,
    meta: {
      title: 'Verifying Email',
    },
  },
  ...errorRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.onError((error, to) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = to.fullPath;

  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});

router.beforeEach((to) => {
  const stringRoute = to?.name?.toString();
  const arrRoute = stringRoute?.split(':');
  let product: string;
  let page: string;

  if (!arrRoute) return;

  if (arrRoute.length > 1) {
    ([product, page] = arrRoute);
  } else {
    // Had this case if the user not yet logged in, so he will have no selected product
    ([page] = arrRoute);
    product = 'none';
  }

  updateTabTitle(page, product ? removePortalSuffix(product as PortalProduct) : '');
});

export default router;
